import { template as template_180426fcc8cf41ba9326b34db54407a4 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_180426fcc8cf41ba9326b34db54407a4(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
