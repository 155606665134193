import { template as template_a4f3d11b9778436896729f8d947cf070 } from "@ember/template-compiler";
const WelcomeHeader = template_a4f3d11b9778436896729f8d947cf070(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
